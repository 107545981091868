import React from 'react';

const Slider = () => {
    return (
        <section class="slider_section">
        <div class="slider_container">
          
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
              <img src="../assets/images/slider/slider_img.jpg" alt="" class="img-slider" />
              <img src="../assets/images/slider/slider_Resp.jpg" alt="" class="img-slider-res" />
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-7">
                      <div class="detail-box">
                        <h1>
                        Velas Aromáticas Hechas a Mano
                        </h1>
                        <p>
                        Descubre velas artesanales únicas con fragancias encantadoras.
                        </p>
                        <a href="https://api.whatsapp.com/send?phone=+573218158767&text=¡Hola! Estoy interesad@ en las velas artesanales"  target="_blank">
                          Contáctanos
                        </a>
                      </div>
                    </div>
                    <div class="col-md-5 ">
                      {/* <div class="img-box">
                        <img src="../assets/images/slider-img.png" alt="" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            
          </div>
            <div class="carousel_btn-box">
              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                <span class="sr-only">Previous</span>
              </a>
              <img src="../assets/images/line.png" alt="" />
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
}
export default Slider 