import React from 'react';


const Footer = () => {
    return (
        <section class="info_section  layout_padding2-top" id='contact'>
        <div class="social_container">
          <div class="social_box">
           {/*  <a href="">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a> */}
           
            <a href="https://www.instagram.com/pabilarte/">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
            
          </div>
        </div>
        <div class="info_container ">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6">
                <h6>
                Nuestro Compromiso
                </h6>
                <p>
                En "Pabilarte", estamos comprometidos a compartir la magia de las velas artesanales con el mundo. Cada vela que creamos cuenta una historia, desde la inspiración inicial hasta la elección de fragancias y colores. Nos enorgullece formar parte de los momentos especiales de nuestros clientes, desde cenas románticas hasta relajantes noches de spa en casa.                
                </p>
              </div>
              <div class="col-md-6 col-lg-6">
                <h6>
                Contáctanos                
                </h6>
                <div class="info_link-box">
                  <a href="">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    <span> Sabaneta - Antioquia </span>
                  </a>
                  <a href="">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <span>+57 3226335019</span>
                  </a>
                  <a href="">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <span> pabilarte0420@gmail.com</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class=" footer_section">
          <div class="container">
            <p>
              &copy; <span id="displayYear"></span> All Rights Reserved By
              <a href="/">Pabilarte</a>
            </p>
          </div>
        </footer>    
      </section>
    )
}
export default Footer