import './App.css';
import Footer from './components/footer';
import Navbar from './components/navbar';
import Section from './components/section';
import Slider from './components/slider';
import Vitrina from './components/vitrina';

function App() {
  return (
    <div class="hero_area">
      <Navbar />
      <Slider />
      <Vitrina />
      <Section />
      <Footer />
    </div>
  );
}

export default App;
