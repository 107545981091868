import React from 'react';
import products from '../api/products';

const Vitrina = () => {
    return (
        <section className="shop_section layout_padding" id="products">
            <div className="container">
                <div className="heading_container heading_center">
                    <h2>Nuestros productos</h2>
                </div>
                <div className="row">
                    {products.map((product, index) => (
                        <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                            <div className="box">

                                    <div className="img-box">
                                        <img src={product.image} alt={product.title} />
                                    </div>
                                  
                                        <div className="detail-box">
                                              <div className="detail-box-grid">
                                                <h6>{product.title}</h6>
                                                <h6>
                                                    <span>${product.precio}</span>
                                                </h6>
                                              </div>
                                              <a  className="detail-link" href={`https://api.whatsapp.com/send?phone=+573218158767&text=¡Hola! Estoy interesado en el producto: ${product.title}. Precio: $${product.precio}.&media=${product.image}`}
                                    target="_blank" rel="noreferrer">Comprar</a>
                                        </div>
                                       
                                   
                                    <div className="new">
                                        <span>New</span>
                                    </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Vitrina;
