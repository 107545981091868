const products = [
    {
        title: 'BUBBLES CUBE 3X3 S',
        desctipcion: 'vela 1',
        precio: '24.000',
        image: '../assets/images/products/IMG_1300.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'BUBBLES CUBE 3X3 M',
        desctipcion: 'vela 2',
        precio: '29.000',
        image: '../assets/images/products/IMG_1301.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'BUBBLES CUBE 2X2',
        desctipcion: 'vela 2',
        precio: '14.000',
        image: '../assets/images/products/IMG_1302.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'RHOMBUS TOWER',
        desctipcion: 'vela 2',
        precio: '19.000',
        image: '../assets/images/products/IMG_1303.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'LADY FLOWERS',
        desctipcion: 'vela 2',
        precio: '25.000',
        image: '../assets/images/products/IMG_1305.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'PILAR ',
        desctipcion: 'vela 2',
        precio: '28.000',
        image: '../assets/images/products/IMG_1306.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'LADY CLOSE EYES',
        desctipcion: 'vela 2',
        precio: '21.000',
        image: '../assets/images/products/IMG_1307.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'MANDARINA',
        desctipcion: 'vela 2',
        precio: '30.000',
        image: '../assets/images/products/IMG_1308.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'BUBBLES',
        desctipcion: 'vela 2',
        precio: '21.000',
        image: '../assets/images/products/IMG_1309.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'MICERINO',
        desctipcion: 'vela 2',
        precio: '16.000',
        image: '../assets/images/products/IMG_1311.jpg',
        tamano: '10cm x 10cm'
    },
    {
        title: 'PANAL',
        desctipcion: 'vela 2',
        precio: '26.000',
        image: '../assets/images/products/IMG_1312.jpg',
        tamano: '10cm x 10cm'
    },
    /* {
        title: 'Vela 2',
        desctipcion: 'vela 2',
        precio: '10.000',
        image: '../assets/images/products/IMG_1313.jpg',
        tamano: '10cm x 10cm'
    }, */
    {
        title: 'CALABAZA',
        desctipcion: 'vela 2',
        precio: '21.000',
        image: '../assets/images/products/IMG_1314.jpg',
        tamano: '10cm x 10cm'
    }
]
export default products;