import React from 'react';


const Section = () => {
    return (
        <section class="saving_section ">
    <div class="box">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="img-box">
              <img src="../assets/images/saving-img.png" alt=""/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="detail-box">
              <div class="heading_container">
                <h2>
                  Nosotros
                </h2>
              </div>
              <p>
                En el corazón de nuestra familia, la pasión por la creatividad y la tradición se unen para dar vida a "Pabilarte". Lo que comenzó como una chispa de inspiración en el rincón de nuestra sala de estar se ha convertido en un apasionante viaje a través del mundo de las velas artesanales.                
                </p>
                <p>
                En "Pabilarte", creemos que las velas no son solo fuentes de luz, sino también portadoras de historias, emociones y ambientes acogedores. Cada vela que producimos es una obra de arte única y hecha a mano con los mejores ingredientes y mucho cariño. Desde nuestras velas perfumadas hasta las creaciones temáticas, nos esforzamos por brindar una experiencia sensorial excepcional a nuestros clientes.
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    )
}
export default Section;